'use strict';

angular.module('cpformplastApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('app-store', {
        url: '/app-store',
        templateUrl: 'app/app-store/app-store.html',
        controller: 'AppStoreController',
        authenticate: [
          'admin',
          'management_dept',
          'logistic_dept',
          'salesmen_dept',
          'mold_dept',
          'quality_dept',
          'production_dept'
        ]
      });
  });
